import Plyr from 'plyr';

const video = (function () {
    let i18nTrad = require('./plyr/src/locales/fr.js');
    let lang = document.querySelector('html').getAttribute('lang');

    try {
        lang = lang === 'fr-FR' ? 'fr' : lang;
        i18nTrad = require('./plyr/src/locales/' + lang + '.js');
    } catch (error) {
        i18nTrad = require('./plyr/src/locales/fr.js');
    }

    function _init() {
        const btns = document.querySelectorAll('.js-show-video');

        for (const btn of btns) {
            btn.addEventListener('click', () => showButton(btn));

            const parent = btn.closest('.video__content');
            if (parent.querySelector('.js-plyr--w-autoplay') !== null) {
                showButton(btn);
            }
        }

        // normal players
        const players = [...document.querySelectorAll('.js-plyr-video')].map(
            p =>
                new Plyr(p, {
                    settings: ['speed'],
                    i18n: i18nTrad.default
                })
        );

        // Handle play/pause event
        players.forEach((player, index) => {
            players[index].on('play', () => {
                for (let index2 = 0; index2 < players.length; index2++) {
                    if (index !== index2) {
                        players[index2].pause(); // Pause other plyr instances
                    }
                }
            });
            players[index].on('ready', e => {
                const iframe = e.target.querySelector(
                    '.plyr__video-embed iframe'
                );
                if (iframe) {
                    iframe.removeAttribute('frameborder');
                    iframe.removeAttribute('width');
                    iframe.removeAttribute('height');
                    iframe.setAttribute('tabindex', '-1');
                }
            });
        });
    }

    function showButton(btn) {
        const { target } = btn.dataset;
        showVideo(target);
    }

    function showVideo(video) {
        const player = new Plyr(video, {
            autoplay: true,
            i18n: i18nTrad.default
        });
        player.on('ready', e => {
            const iframe = e.target.querySelector('.plyr__video-embed iframe');
            if (iframe) {
                iframe.removeAttribute('frameborder');
                iframe.removeAttribute('width');
                iframe.removeAttribute('height');
                iframe.setAttribute('tabindex', '-1');
            }
        });
    }

    return { init: _init };
})();

export default video;
