const glossary = (function () {
    function _init() {
        if (
            typeof glossaryList !== 'undefined' &&
            document.querySelector('.wysiwyg')
        ) {
            parseAndReplace(glossaryList);
        }
    }
    function parseAndReplace(glossaryList) {
        const links = document.querySelectorAll('.wysiwyg a');
        links.forEach(link => {
            const href = link.href;
            if (Object.prototype.hasOwnProperty.call(glossaryList, href)) {
                link.className = 'btn-popover';
                link.setAttribute('data-bs-toggle', 'popover');
                link.setAttribute('data-bs-html', 'true');
                link.setAttribute('data-bs-sanitize', 'false');
                link.setAttribute(
                    'data-bs-content',
                    '<div class="card card-inca card-inca-w-border card-inca-light p-0"><div class="card-body p-1 flex-column gap-1">' +
                        '<p class="card-title order-0 pe-0">' +
                        link.textContent +
                        '</p>' +
                        glossaryList[href] +
                        '</div></div>'
                );
            }
        });
    }
    return { init: _init };
})();

export default glossary;
