const stickyMenu = (function () {
    function _init() {
        const linksSticky = document.querySelectorAll('.js-sticky-menu a');
        if (linksSticky) {
            for (const elt of linksSticky) {
                elt.addEventListener('focus', function () {
                    elt.closest('.js-sticky-menu').classList.add('has-focus');
                });

                elt.addEventListener('blur', function () {
                    elt.closest('.js-sticky-menu').classList.remove(
                        'has-focus'
                    );
                });

                elt.addEventListener('mouseover', function () {
                    elt.closest('.js-sticky-menu').classList.add('has-hover');
                });

                elt.addEventListener('mouseout', function () {
                    elt.closest('.js-sticky-menu').classList.remove(
                        'has-hover'
                    );
                });

                document.addEventListener('keydown', function (event) {
                    const key = event.key;
                    if (key === 'Escape') {
                        elt.closest('.js-sticky-menu').classList.remove(
                            'has-focus'
                        );
                    }
                });
            }
        }
    }

    return { init: _init };
})();

export default stickyMenu;
