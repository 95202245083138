// ==========================================================================
// Plyr French translation
// ==========================================================================

const i18nTrad = {
    restart: 'Reprendre',
    rewind: 'Rembobiner {seektime} secondes',
    play: 'Lire',
    pause: 'Pause',
    fastForward: 'Avancer {seektime} secondes',
    seek: 'Chercher',
    played: 'Jouée',
    buffered: 'Mis en mémoire tampon',
    currentTime: 'Temps écoulé',
    duration: 'Durée',
    volume: 'Volume',
    mute: 'Muet',
    unmute: 'réactiver le son',
    enableCaptions: 'Activer les sous-titres',
    disableCaptions: 'Désactiver les sous-titres',
    enterFullscreen: 'Passer en mode plein écran',
    exitFullscreen: 'Quitter le mode plein écran',
    frameTitle: '{title}',
    captions: 'Légendes',
    settings: 'Paramètres',
    speed: 'Vitesse',
    normal: 'Normal',
    quality: 'Qualité',
    loop: 'Boucle',
    start: 'Commencer',
    end: 'Fin',
    all: 'Tout',
    reset: 'Réinitialiser',
    disabled: 'Désactivé',
    advertisement: 'publicité',
    qualityBadge: {
        2160: '4K',
        1440: 'HD',
        1080: 'HD',
        720: 'HD',
        576: 'SD',
        480: 'SD'
    }
};

export default i18nTrad;
