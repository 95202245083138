const visualInsertFocus = (function () {
    function _init() {
        const textCards = document.querySelectorAll(
            '.js-visual-insert .card-end'
        );
        if (textCards) {
            for (const textCard of textCards) {
                const linkCard = textCard
                    .closest('.card-inca')
                    .querySelector('.stretched-link');

                linkCard.addEventListener('focus', function () {
                    linkCard.closest('.card-inca').classList.add('has-focus');
                });

                linkCard.addEventListener('blur', function () {
                    linkCard
                        .closest('.card-inca')
                        .classList.remove('has-focus');
                });
            }
        }
    }

    return { init: _init };
})();

export default visualInsertFocus;
