import video from './components/video';
import breadcrumbs from './components/breadcrumbs';
import goToTop from './components/goToTop';
import sliderReadMore from './components/sliderReadMore';
import sliderVisualInsert from './components/sliderVisualInsert';
import visualInsertFocus from './components/visualInsertFocus';
import stickyMenu from './components/stickyMenu';
import titleBlock from './components/titleBlock';
import glossary from './components/glossary';
import popoverFunc from './components/popover';

window.WOW = require('wowjs').WOW;

video.init();
breadcrumbs.init();
goToTop.init();
sliderReadMore.init();
sliderVisualInsert.init();
visualInsertFocus.init();
stickyMenu.init();
titleBlock.init();
glossary.init();
popoverFunc.init();

new WOW().init();
