const breadcrumbs = (function () {
    function _init() {
        const breadcrumbs = document.querySelectorAll('nav.js-breadcrumb');
        for (const breadcrumb of breadcrumbs) {
            const controlButton = breadcrumb.querySelector(' .breadcrumb-btn');
            if (controlButton) {
                controlButton.addEventListener(
                    'click',
                    handleClick.bind(breadcrumb)
                );
            }
        }
        function handleClick() {
            const countedItems = this.querySelectorAll('.breadcrumb-counted');
            countedItems.forEach(item => {
                item.classList.add('show');
            });

            const controlElement = this.querySelector('.breadcrumb-control');
            controlElement.classList.add('d-none');
        }
    }

    return { init: _init };
})();

export default breadcrumbs;
