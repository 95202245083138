import '@accessible360/accessible-slick';
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

const sliderReadMore = (function () {
    function _init() {
        function handleResize() {
            const slidersReadMore = document.querySelectorAll(
                '.js-read-more-slider'
            );
            if (slidersReadMore) {
                for (const sliderReadMoreElmt of slidersReadMore) {
                    const lenghtList =
                        sliderReadMoreElmt.querySelectorAll(
                            '[class*="col-"]'
                        ).length;
                    if (
                        lenghtList > 1 &&
                        window.matchMedia('(max-width: 991.98px)').matches
                    ) {
                        fnSlickReadMore(sliderReadMoreElmt);
                    } else {
                        if (
                            sliderReadMoreElmt.classList.contains(
                                'slick-initialized'
                            )
                        ) {
                            $(sliderReadMoreElmt).slick('unslick');
                            $(sliderReadMoreElmt)
                                .next('.slider-paging')
                                .html('');
                        }
                        $(sliderReadMoreElmt).attr('role', 'list');
                        $('.js-role-list-item', sliderReadMoreElmt).attr(
                            'role',
                            'listitem'
                        );
                    }
                }
            }
        }

        handleResize();

        window.addEventListener('resize', function () {
            handleResize();
        });
    }

    function fnSlickReadMore(el) {
        const $sliderCards = $(el);
        const $status = $sliderCards.next('.slider-paging');

        if (!$sliderCards.hasClass('slick-initialized')) {
            $sliderCards.on(
                'init reInit afterChange',
                function (event, slick, currentSlide, nextSlide) {
                    const i = (currentSlide || 0) + 1;
                    $status.html(
                        '<span class="visually-hidden">' +
                            $sliderCards.data('text') +
                            '</span>' +
                            ' ' +
                            i +
                            '/' +
                            slick.slideCount
                    );
                    $('.js-role-list-item', $sliderCards).removeAttr('role');
                }
            );

            $sliderCards.slick({
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                arrowsPlacement: 'afterSlides',
                prevArrow:
                    '<button type="button" class="slick-prev"><span class="material-symbols-outlined" aria-hidden="true">arrow_back</span><span class="slick-sr-only">' +
                    $sliderCards.data('prev') +
                    '</span></button>',
                nextArrow:
                    '<button type="button" class="slick-next"><span class="material-symbols-outlined" aria-hidden="true">arrow_forward</span><span class="slick-sr-only">' +
                    $sliderCards.data('next') +
                    '</span></button>',
                regionLabel: $sliderCards.data('label')
            });
        }
    }

    return { init: _init };
})();

export default sliderReadMore;
