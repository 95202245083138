const titleBlock = (function () {
    function _init() {
        switchTitle();

        window.addEventListener('resize', switchTitle);
    }

    function switchTitle() {
        const titles = document.querySelectorAll('.js-title-block');
        for (const title of titles) {
            const currentBlock = title.closest('.landing-page__block');
            let nextBlock = null;
            let prevBlock = null;

            if (currentBlock.nextElementSibling) {
                nextBlock = currentBlock.nextElementSibling.children[0];
                nextBlock.classList.add('block-w-Title');
            }

            if (currentBlock.previousElementSibling) {
                prevBlock = currentBlock.previousElementSibling.children[0];
                if (
                    prevBlock &&
                    prevBlock.classList.contains('block-bg-dark')
                ) {
                    title.closest('.title-block').classList.add('block');
                }
            }

            if (title.hasAttribute('data-title-mobile')) {
                if (window.innerWidth < 768) {
                    const titleHTMLMobile =
                        title.getAttribute('data-title-mobile');
                    title.innerHTML = titleHTMLMobile;
                } else {
                    const titleHTMLDesktop =
                        title.getAttribute('data-title-desktop');
                    title.innerHTML = titleHTMLDesktop;
                }
            }
        }
    }

    return { init: _init };
})();

export default titleBlock;
