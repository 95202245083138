const goToTop = (function () {
    function _init() {
        const gotoTop = document.querySelectorAll('.js-go-to-top');
        const toTopButton = document.querySelector('.js-to-top');
        const focusElement = document.getElementById('top-page');

        if (toTopButton) {
            document.addEventListener('scroll', function () {
                const scrollPosition =
                    window.scrollY || document.documentElement.scrollTop;
                const screenWidth = window.innerWidth;

                if (scrollPosition > 300 && screenWidth > 767) {
                    toTopButton.classList.add('d-md-flex');
                } else {
                    toTopButton.classList.remove('d-md-flex');
                }
            });
        }

        if (gotoTop) {
            for (const elt of gotoTop) {
                const backToTop = () => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    focusElement.focus();
                };
                elt.addEventListener('click', backToTop);
            }
        }
    }

    return { init: _init };
})();

export default goToTop;
