import * as bootstrap from 'bootstrap';

const popoverFunc = (function () {
    function _init() {
        let popoverList = [];

        // initializing, adding class for activated to prevent firing multiple times via observer below
        function initializePopovers() {
            const popoverTriggerList = document.querySelectorAll(
                '[data-bs-toggle="popover"]:not(.popover-activated)'
            );

            const newPopoverList = [];

            popoverTriggerList.forEach(function (popoverTriggerEl) {
                if (!popoverTriggerEl.getAttribute('data-bs-original-title')) {
                    const newPopover = new bootstrap.Popover(popoverTriggerEl, {
                        trigger: 'hover focus'
                    });
                    newPopoverList.push(newPopover);
                    popoverTriggerEl.classList.add('popover-activated');
                }
            });

            popoverList = popoverList.concat(newPopoverList); // update popover list, need to close created

            return newPopoverList;
        }

        // initialize on load
        initializePopovers();

        // close on Esc btn
        function closePopoversOnEscape(event) {
            if (event.key === 'Escape') {
                popoverList.forEach(function (popover) {
                    popover.hide();
                });

                if (document.activeElement) {
                    document.activeElement.blur();
                }
            }
        }

        document.addEventListener('keydown', closePopoversOnEscape);

        // MutationObserver for DOM changes
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.addedNodes.length) {
                    // reinit if need
                    popoverList = initializePopovers();
                }
            });
        });

        // observing
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    return { init: _init };
})();

export default popoverFunc;
